import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import _ from 'lodash';

import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore'


Vue.use(Vuex)

// TODO: handle state
const defaultState = () => {
	return {
		userInfo: {},
		msgCollection: null,
		conversations: [],
		pendingConversations: [],
		session: sessionStorage.getItem("HPC_TOKEN"),
		// customerList: [],
		firestore: null,
		agentList: [],
		activeMessages: [],
		assignedAgent: {},
		rawMessageList: [],
		rawProductList: [],
		unsubscribeActiveMessage: null,
	}
}

// TODO: state
const state = defaultState

// TODO: handle mutations
const mutations = {

	storeInfo (state, info) {
		state.userInfo = info
	},

	// roles and permissions
	// setCustomer(state, response) {
	// 	state.customerList = response
	// },
	setActiveMessages(state, response) {
		state.activeMessages = response
	},
	setAssignedAgent(state, response) {
		state.assignedAgent = response
	},
	storeSession(state, response) {
		state.session = response
	},
	setCollection(state, response) {
		state.msgCollection = response
	},
	setFirestore(state, response) {
		state.firestore = response
	}
}

// TODO: handle actions
const actions = {
	getCollection({commit, state}) {
		const config = process.env;

		const firebaseConfig = {
			apiKey: config.VUE_APP_API_KEY,
			authDomain: config.VUE_APP_AUTH_DOMAIN,
			projectId: config.VUE_APP_PROJECT_ID,
			storageBucket: config.VUE_APP_STORAGE_BUCKET,
			messagingSenderId: config.VUE_APP_MESSAGING_SENDER_ID,
			appId: config.VUE_APP_APPID
		};
		
		firebase.initializeApp(firebaseConfig);
		
		const firestore = firebase.firestore();


		commit('setFirestore',  firestore);
		commit('setCollection',  firestore.collection(config.VUE_APP_DB_COLLECTION));
	
	},
	getCustomers({commit, state}, user) {

		// TODO: remove current content
		// state.customerList = []
		// set end point
		let endpoints = ['/wp-json/wp/v2/users?roles=agent'];
		// call all endpoints at once
		return axios.all(
			endpoints.map(endpoint => axios.get(endpoint, {
				headers: {
					"Authorization" : "Bearer " + state.session,
				}
			}))).then(axios.spread((agents) => {
				console.warn({agents });
				// let pList = products.data;
				// state.rawProductList = _.cloneDeep(pList);

				// let list = customers.data;

				// get agent list
				state.agentList = agents.data;

				// commit('setCustomer', list);

				// let customerIds = list.map(i => i.id);
				let messagesQuery;
				// console.info(customerIds)
				if (user.type === 'customer') {
					messagesQuery = state.msgCollection
					.where("requestId", "in", [user.id])
					.orderBy('createdAt', 'desc');
				} else {
					messagesQuery = state.msgCollection
					.orderBy('createdAt', 'desc');
				}

				// state.conversations = [];
				messagesQuery.onSnapshot(snapshop => {
					let data = snapshop.docs.map(doc => ({id: doc.id, ...doc.data()}));
					// add product details each row
					// data.map(item => item.productDetails = pList.filter(i => i.id === item.productId)[0] || {});

					// if custoer = should be filtered by request id === customer logged id
					// if (user.type === 'customer') {
					// 	data = data.filter(i => i.requestId === user.id);
					// }

					// get convo with unique by productId
					let ls = _.uniqBy(data, function(item) { return [item.productId, item.requestId, item.chatType, item.orderId].join(); })//'productId,requestId');
					// console.info(customerIds,data)
					// console.info("ls",ls)

					// build unread message counter
					var counts = {};
					data.forEach((el, i, arr) => {
						// (el.message != '' && el.receiverId != 0) => customer requested a chat [considered as newly created]
						if (counts[el.requestId] && !el.read && (el.message != '' && el.receiverId != 0) && el.receiverId === user.id) {
							// check if existing request id but different product
							if (counts[el.requestId][el.productId]) {
								counts[el.requestId][el.productId] = parseInt(counts[el.requestId][el.productId]) + 1;
							} else {
								counts[el.requestId][el.productId] = 1;
							}

							// console.info('>>>',counts[el.requestId][el.productId])
						} else if (!el.read && (el.message != '' && el.receiverId != 0) && el.receiverId === user.id){
							counts[el.requestId] = {};
							counts[el.requestId][el.productId] = 1;
							// console.info('>>>>>>',counts[el.requestId][el.productId])
						} else {
							// counts[el.requestId] = {};
						}
						// counts[key] = counts[key] === undefined ? 1 : (counts[key] += 1);
					});
					// console.warn(counts);

					if (user.type === 'customer') {
						state.conversations = ls.map(i => {i.unread = counts[i.requestId] ? counts[i.requestId][i.productId] : 0; return i});
					} else {
						// ls = _.uniqBy(data.filter(item => item.receiverId === user.id), 'productId');
						const allConvo = ls.map(i => {i.unread = counts[i.requestId] ? counts[i.requestId][i.productId] : 0; return i});
						// console.info(allConvo);
						state.conversations = allConvo.filter(item => item.receiverId === user.id || item.senderId === user.id);
						state.pendingConversations = allConvo.filter(item => item.receiverId === 0);
					}

				});
			})
		)
	},

	getActiveConversation({commit, state, dispatch}, params) {
		// state.activeMessages = [];
		console.info(params);

		if (state.unsubscribeActiveMessage) {
		// 	console.info('state.unsubscribeActiveMessage',state.unsubscribeActiveMessage)
			state.unsubscribeActiveMessage();
		}

		let messagesQuery;
		console.info(params.chat_type, params.order_id)
		if (params.chat_type === 'product_list' && params.order_id) {
			messagesQuery = state.msgCollection
			.where("orderId", "==", params.order_id)
			.where("productId", "==", params.product_id)
			.where("requestId", "==", params.user_id)
			.where("chatType", "==", params.chat_type)
			.orderBy('createdAt', 'desc').limit(100);
		} else {
			messagesQuery = state.msgCollection
			.where("productId", "==", params.product_id)
			.where("requestId", "==", params.user_id)
			.where("chatType", "==", params.chat_type)
			.orderBy('createdAt', 'desc').limit(100);
		}

		state.unsubscribeActiveMessage = messagesQuery.onSnapshot(snapshop => {
			state.rawMessageList = snapshop.docs.map(doc => ({id: doc.id, ...doc.data()}))
			// remove messages without content and unassigned
			// let filteredMessages = state.rawMessageList.filter(item => item.message != '' && item.requestId != 0);
			let filteredMessages = _.cloneDeep(state.rawMessageList);
			/* filteredMessages.map(item => {
				if (item.cartContents) {
					let pids = item.cartContents.split(',');
					// item.productDetails = state.rawProductList.filter(i => pids.indexOf((i.id).toString()) >= 0) || {};
					let qty = item.cartContentsQuantity.split(',');
					item.quantities = {};
					for (let i = 0; i < pids.length; i++) {
						const element = parseInt(pids[i]);
						item.quantities[element] = qty[i]
					}

				} else {

					// item.productDetails = state.rawProductList.filter(i => i.id === item.productId)[0] || {};
				}

			}); */
			// console.info("rawMessageList", filteredMessages);
			// console.info("rawMessageList", state.rawMessageList);

			// update to read messages
			dispatch('setMessagesToRead', params);

			commit('setActiveMessages',  filteredMessages.reverse());
			// get assigned agent
			if (params.logged_type === 'customer') {
				let convoMessages = state.activeMessages;
				let lastElement = _.findLast(convoMessages, i => i.receiverId === params.logged_user_id);
				commit('setAssignedAgent', (lastElement || {}))
			}

			if (params.callback) {
				params.callback();
			}
		});
	},

	setMessagesToRead({commit, state}, params) {
		// Initializing a write batch...
		var batch = state.firestore.batch();
		let unreadList = state.rawMessageList.filter(item => !item.read && item.senderId != params.logged_user_id);
		// console.info(unreadList, params)
		// contruct batch update read message
		unreadList.map(item => {
			const messagesQuery = state.msgCollection.doc(item.id);
			batch.update(messagesQuery, {read: true});
		})

		// commit bulk update of read message
		batch.commit();
	},

	sendMessage({commit, state}, params) {
		let o = {
			productId: params.product_id,
			productDetails: params.product_details || [],
			message: params.message,
			read: false,
			createdAt: Date.now(),
			modifiedAt: null,
			requestId: params.user_id,
			details: params.customer_details || {},
			receiverId: params.logged_type === 'agent' ? params.user_id : params.assigned_agent,
			senderId: params.logged_type === 'agent' ? params.logged_user_id : params.user_id,
			agentName: params.agent_name || ''
		}
		// console.info('send: ',o);

		if (params.remark) {
			o.remark = params.remark;
		}

		// if agent mark the convo as close (automatic unassign)
		// console.info(params)
		if (params.remark === 'close' && params.logged_type === 'agent') {
			o.senderId = 0;
		}

		/* if (params.cart_contents && params.remark === 'cart_contents') {
			// o.cartContents = params.cart_contents;
			// o.cartContentsQuantity = params.cart_contents_quantity;
			o.chatType = params.chat_type;
		} else  */
		if (params.chat_type) {
			o.chatType = params.chat_type;
		}

		// append order id
		if (params.chat_type === 'product_list' && params.order_id) {
			o.orderId = params.order_id;
		}

		// add collection
		return state.msgCollection.add(o);
	},
	setSession({commit}, session) {
		commit('storeSession', session)
	},
	unsetMessage({commit, state}) {
		// need to unsubscribe message
		if (state.unsubscribeActiveMessage) {
			state.unsubscribeActiveMessage();
		}

		commit('setActiveMessages', [])
	}
}

// TODO: handle getters
const getters = {
	userInfo : state => {
			return state.userInfo
	},
	// customerList : state => {
	// 	return state.customerList;
	// },
	activeMessages : state => {
		return state.activeMessages;
	},
	assignedAgent : state => {
		return state.assignedAgent;
	},

}

// TODO: handle modules
const modules = {}
export default new Vuex.Store({
	state,
    getters,
    actions,
    mutations,
	modules
})
