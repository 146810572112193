import axios from 'axios';

let mxnProducts = {
    getProductList: (params)=> {
        return axios.get('/wp-json/wc/v3/products',
        {
            headers: {
                "Authorization" : "Bearer " + sessionStorage.getItem("HPC_TOKEN")
            }
        });
    },
    getProductDetail: (params)=> {
        // console.info(params)
        return axios.get('/wp-json/wc/v3/products',
        {
            params: params,
            headers: {
                "Authorization" : "Bearer " + sessionStorage.getItem("HPC_TOKEN")
            }
        });
    }
}

export default mxnProducts;
