<template>
  <div id="app">
    <!-- <h3>Hello {{agentName}}</h3>
    <img alt="Vue logo" src="./assets/logo.png"> -->
    <AgentChat />
  </div>
</template>

<script>
import AgentChat from './views/ChatMessages.vue'

export default {
  name: 'App',
  components: {
    AgentChat
  },
  data() {
    return {
      agentName: "HPC Agent",
    }
  },
  mounted() {
    // document.title = "Customer Chat";
    let element = document.getElementById("hpc-chat-app");
    this.agentName = element.getAttribute("data-username");
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
