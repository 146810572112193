import swal from 'sweetalert2'
let mxnSwal = {
    created: () => {
        // init
    },
    data() {
        return {
            Toast: null
        }
    },
    toast: (icon, title) => {
        const toast = swal.mixin({
            toast: true,
            position: 'top',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', swal.stopTimer)
                toast.addEventListener('mouseleave', swal.resumeTimer)
            }
        })

        toast.fire({
            icon: icon,
            title: title
        })
    },
    confirmation: (o) => {
        // icon: "success", "error", "warning", "info" or "question"
        swal.fire({
            title: o.title,
            text: o.msg,
            icon: o.icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            // showClass: {
            //     popup: 'hpc-confirm-modal animate__animated animate__fadeInDown'
            // },
            // hideClass: {
            //     popup: 'hpc-confirm-modal animate__animated animate__fadeOutUp'
            // },
            customClass: {
                title: 'hpc-confirm-title',
                htmlContainer: 'hpc-confirm-content',
                container: 'hpc-confirm-modal'
            }
          }).then((result) => {
            if (result.isConfirmed) {
                if (o.confirmed) {
                    o.confirmed();
                }
            }
          })
    },
    selectConfirmation: (o) => {
        swal.fire({
            title: o.title,
            input: 'select',
            inputOptions: o.options,
            inputPlaceholder: o.placeholder,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            customClass: {
                title: 'hpc-confirm-title',
                htmlContainer: 'hpc-confirm-content',
                input: 'hpc-select-agent',
                container: 'hpc-confirm-modal'
            },
            inputValidator: (value) => {
                
            if (o.selectedValue) {
                o.selectedValue(value)
            }
                
            }
          })
    },
    infoDetails: (o) => {
        swal.fire({
            title: o.title,
            icon: 'info',
            html: o.html ,
            showCloseButton: true,
            showCancelButton: true,
            // focusConfirm: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            customClass: {
                title: 'hpc-confirm-title',
                htmlContainer: 'hpc-confirm-content',
                input: 'hpc-select-agent',
                container: 'hpc-confirm-modal'
            }
          })
    },
    alert: (o) => {
      swal.fire(
        o.title,
        o.message,
        o.type
      )
    },
    inputOrder: async (o) => {
        const { value: order } = await swal.fire({
            title: 'Send payment link',
            input: 'number',
            inputLabel: 'Please input order number for payment',
            inputPlaceholder: 'Order Number',
            inputValidator: (value) => {
                // console.info(value);
                if (value) {
                    o.handler(value)
                }
            }
        })
          if (!order) {
            swal.fire(`Invalid input`)
          }
    }
}
export default mxnSwal;
