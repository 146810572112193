import axios from 'axios';

let mxnAuth = {
    authJWT: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.post('/wp-json/jwt-auth/v1/token',
        params);
    },
    chatToken: (params)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.get('/wp-json/hpc/v1/chat-init');
    }
}

export default mxnAuth;
