import Vue from 'vue';
import moment from 'moment'

Vue.filter('date', function(value, hasFomat) {
    let format = hasFomat || 'MMMM DD, YYYY hh:mmA';
    if (!isNaN(value)) {
        return moment(parseFloat(value)).format(format)
    } 
    
    return moment(new Date(value)).format(format);
})
