import axios from 'axios';

let mxnCustomer = {
    getCustomer: (id)=> {
        // let session = localStorage.getItem('_SESSION');
        return axios.get('/wp-json/wc/v3/customers/' + id,
        {
            headers: {
                "Authorization" : "Bearer " + sessionStorage.getItem("HPC_TOKEN")
            }
        });
    }
}

export default mxnCustomer;
