import Vue from 'vue';
import App from './App.vue';
// import './firebase';
import store from './store';
import './assets/style.css';
import './filters/filter';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
